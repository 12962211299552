<template>
  <div id="SLACompliance" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">TOP 10 CASES</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <highcharts :options="chartOptions"></highcharts>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">TOP 10 CASES ({{ reports.length }})</div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="reports"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                trigger: 'enter'
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: [{ field: 'Count', type: 'asc' }]
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <div slot="table-actions">
                <div class="input-group mb-2 mr-sm-2">
                  <label class="mt-2 ml-5">Date Range:</label>
                  <RangedatePicker @selected="selectedDateRange" i18n="EN" class="mr-5" righttoleft="true" />
                  <a :href="downloadLink" class="btn btn-sm btn-secondary ml-4">Download</a>
                </div>
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Chart } from 'highcharts-vue'
import RangedatePicker from 'vue-rangedate-picker'
import ISO8601Date from '../../helper/ISO8601Date'

export default {
  name: 'Top10Cases',

  components: {
    highcharts: Chart,
    RangedatePicker
  },

  data() {
    return {
      reports: [],
      spinner: false,
      startDate: '',
      endDate: '',
      downloadLink: ''
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.spinner = true
      axios.get(`/reports/top-10-cases?start=${this.startDate}&end=${this.endDate}`).then(res => {
        if (res.data.status == 'success') {
          this.reports = res.data.reports
          this.downloadLink = res.data.downloadLink
        }
        this.spinner = true
      })
    },

    selectedDateRange(v) {
      this.startDate = ISO8601Date(v.start)
      this.endDate = ISO8601Date(v.end)
      this.loadData()
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'ISSUE TYPE',
          field: 'Subcategory',
          sortable: true
        },

        {
          label: 'CATEGORY',
          field: 'Category',
          sortable: true
        },

        {
          label: 'COUNT',
          field: 'Count',
          sortable: true
        },

        {
          label: 'DEPENDENCY',
          field: 'Department',
          sortable: true
        }

        /* {
          label: "CategoryType",
          field: "CategoryType",
          sortable: true,
        }, */
      ]
      return cols
    },

    chartOptions() {
      let names = []
      let count = []

      for (let index = 0; index < this.reports.length; index++) {
        names.push(this.reports[index].Subcategory)
        count.push(parseInt(this.reports[index].Count))
      }

      return {
        chart: {
          type: 'spline'
          //type: "column",
          //type: "area",
          //type: "line",
        },
        series: [
          {
            name: 'ISSUE TYPE',
            data: count,
            color: '#e81500'
          }
        ],
        xAxis: {
          categories: names,
          crosshair: true
        },
        yAxis: {
          title: {
            text: 'Count'
          }
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        credits: {
          enabled: false
        }
      }
    }
  }
}
</script>

<style></style>
